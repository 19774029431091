// TODO: Create account
import { Component } from "react";
import styled from "styled-components";

// Utils
import { rem, Color, Opacity } from "../../utils/style";
import { createAccount, getSupportMailTo } from "../../utils/authenticate";
import { redirectReplaceHistory } from "../../utils/redirect";
import { emailRegex } from "../../utils/helpers";
import intl from "../../services/intl";

// Components
import RitualSubmitButton from "../global/RitualSubmitButton";
import Text from "../Text";

import debounce from "lodash/debounce";
import { validateEmail } from "../../services/user-service";
import { variation } from "../../services/launchDarkly";
import metrics from "../../utils/metrics";
import { trackSignUp } from "../../utils/tracking/authentication";

// Styled Elements
const Form = styled.form.attrs({
  name: "create",
})`
  legend {
    display: none;
  }
`;

const InputGroup = styled.div`
  margin-bottom: 32px;

  &.error,
  &.hint {
    margin-bottom: 12px;
  }

  &:last-of-type {
    margin-bottom: 40px;

    &.error {
      margin-bottom: 20px;
    }
  }
`;

const Label = styled.label`
  font-size: ${rem(14)};
  font-weight: 300;
  line-height: ${rem(20)};
  margin-bottom: 8px;

  &.error {
    color: ${Color.ritualRed};
  }

  a {
    color: inherit;
    text-decoration: underline;
  }

  &.error-label,
  &.hint-label {
    display: none;
    font-size: ${rem(12)};
    font-weight: 300;
    line-height: ${rem(16)};
    padding-top: 4px;

    &.error,
    &.hint {
      display: block;
    }
  }
`;

const Input = styled.input.attrs({
  required: "",
})`
  height: 44px;
  width: 100%;
  color: ${Color.ritualBlue};
  display: block;
  font-size: ${rem(16)};
  line-height: ${rem(22)};
  padding: 0 1rem;
  font-weight: 300;
  background-color: #fff;
  border-radius: 0;
  border: 1px solid ${Color.ritualSecondaryDisabledBlue};
  caret-color: ${Color.ritualBlue};

  [data-whatintent="mouse"] &:focus,
  [data-whatintent="touch"] &:focus {
    outline: none;
  }

  &.error {
    border: 1px solid ${Color.ritualRed};
    color: ${Color.ritualRed};
  }
`;

const SuggestionButton = styled.button`
  background: transparent;
  border: 0;
  padding: 0;
  margin: 0;
  color: ${Color.ritualBlue};
  font-weight: bolder;
  text-decoration: underline;

  &:hover {
    opacity: ${Opacity.light};
  }
`;

const nameRegex = /^.{1,40}$/;

export default class CreateForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      processing: false,
      firstName: "",
      isFirstNameValid: false,
      lastName: "",
      isLastNameValid: false,
      email: "",
      isEmailValid: false,
      password: "",
      isPasswordValid: false,
      error: false,
      errorMessage: "",
      emailValidationEnabled: false,
    };
    this.validateEmailServerSide = debounce(this.validateEmailServerSide, 500);
  }

  componentDidMount() {
    const emailValidationEnabled = variation("send-grid-email-validation");

    this.setState({
      emailValidationEnabled,
    });
  }

  handleUserCreation(e) {
    e.preventDefault();
    const { firstName, lastName, email, password } = this.state;
    const { redirect } = this.props;

    this.setState({
      processing: true,
    });

    createAccount(firstName, lastName, email, password)
      .then(() => {
        trackSignUp("email-password", {
          marketingPreference: this.props.marketingPreference,
        });

        redirectReplaceHistory(redirect);
      })
      .catch((error) => {
        // User creation unsuccessful
        let errorMessage = intl.t(
          "authenticate.create.error",
          "Something went wrong, try again",
        );

        if (
          error.status === 422 &&
          error.errors[0] &&
          error.errors[0].source.pointer.includes("email")
        ) {
          errorMessage = intl.t(
            "authenticate.create.error-email",
            `Email {error}`,
            { error: error.errors[0].detail },
          );
        }

        if (
          error.error === "deny" ||
          error.errors?.find((e) => e.detail === "deny")
        ) {
          metrics.track("Denial Policy Presented", {
            email,
            location: "sign-up",
          });
          errorMessage = intl.t(
            "authenticate.errors.deny-sign-up",
            "We were unable to create your account as we have detected suspicious activity. Please email customer support for assistance.",
            {
              mailTo: getSupportMailTo("deny-sign-up", { email }),
            },
          );
        }

        errorMessage = this.setState({
          error: true,
          errorMessage,
        });
      })
      .finally(() => {
        this.setState({
          processing: false,
        });
      });
  }

  handleFirstNameValidation(e) {
    const updatedFirstName = e.target.value;
    const isFirstNameValid = nameRegex.test(updatedFirstName);

    // clear error on change
    this.setState({
      firstName: updatedFirstName,
      isFirstNameValid,
      error: false,
      errorMessage: "",
    });
  }

  handleLastNameValidation(e) {
    const updatedLastName = e.target.value;
    const isLastNameValid = nameRegex.test(updatedLastName);

    // clear error on change
    this.setState({
      lastName: updatedLastName,
      isLastNameValid,
      error: false,
      errorMessage: "",
    });
  }

  handleEmailValidation(e) {
    const updatedEmail = e.target.value;
    const isEmailValid = emailRegex.test(updatedEmail);

    if (isEmailValid) {
      if (this.state.emailValidationEnabled) {
        this.validateEmailServerSide(updatedEmail);
      }
    } else {
      this.setState({
        emailSuggestion: null,
      });
    }

    // clear error on change
    this.setState({
      email: updatedEmail,
      isEmailValid,
      error: false,
      errorMessage: "",
    });
  }

  handlePasswordValidation(e) {
    const passwordRegex = /^.{6,}$/;
    const updatedPassword = e.target.value;
    const isPasswordValid = passwordRegex.test(updatedPassword);

    // clear error on change
    this.setState({
      password: updatedPassword,
      isPasswordValid,
      error: false,
      errorMessage: "",
    });
  }

  render() {
    let disabled = false;

    const {
      isFirstNameValid,
      isLastNameValid,
      isEmailValid,
      isPasswordValid,
      error,
      errorMessage,
      processing,
    } = this.state;

    disabled =
      !isFirstNameValid ||
      !isLastNameValid ||
      !isEmailValid ||
      !isPasswordValid ||
      processing;

    const emailSuggestion = this.state.emailSuggestion;
    return (
      <Form onSubmit={this.handleUserCreation.bind(this)}>
        <fieldset>
          <legend>
            <Text
              id="authenticate.create.sign-up-form"
              defaultMessage="Sign Up Form"
            />
          </legend>
          <InputGroup>
            <Label htmlFor="fname">
              <Text
                id="authenticate.create.first-name"
                defaultMessage="First Name"
              />
            </Label>
            <Input
              className="first-name-input"
              id="fname"
              type="text"
              name="fname"
              autoCapitalize="on"
              autoComplete="given-name"
              value={this.state.firstName}
              onChange={this.handleFirstNameValidation.bind(this)}
            />
          </InputGroup>

          <InputGroup>
            <Label htmlFor="lname">
              <Text
                id="authenticate.create.last-name"
                defaultMessage="Last Name"
              />
            </Label>
            <Input
              className="last-name-input"
              id="lname"
              type="text"
              name="lname"
              autoCapitalize="on"
              autoComplete="family-name"
              value={this.state.lastName}
              onChange={this.handleLastNameValidation.bind(this)}
            />
          </InputGroup>

          <InputGroup
            className={`${error ? "error" : ""} ${
              emailSuggestion ? "hint" : ""
            }`}
          >
            <Label
              htmlFor="email"
              className={`email-label ${error ? "error" : ""}`}
            >
              <Text id="authenticate.create.email" defaultMessage="Email" />
            </Label>
            <Input
              className={`email-input ${error ? "error" : ""}`}
              id="email"
              type="text"
              name="email"
              autoCapitalize="off"
              autoComplete="email"
              value={this.state.email}
              onChange={this.handleEmailValidation.bind(this)}
            />
            <Label
              htmlFor="email"
              className={`error-label ${error ? "error" : ""}`}
              dangerouslySetInnerHTML={{ __html: errorMessage }}
            />
            <Label
              htmlFor="email"
              data-test-email-suggestion
              className={`hint-label ${emailSuggestion ? "hint" : ""}`}
            >
              {emailSuggestion}
            </Label>
          </InputGroup>

          <InputGroup>
            <Label htmlFor="password">
              <Text
                id="authenticate.create.password"
                defaultMessage="Password"
              />
            </Label>
            <Input
              className="password-input"
              id="password"
              type="password"
              name="password"
              autoCapitalize="off"
              autoComplete="new-password"
              value={this.state.password}
              onChange={this.handlePasswordValidation.bind(this)}
            />
          </InputGroup>

          <RitualSubmitButton
            className={`submit-button fullwidth ${disabled ? "disabled" : ""}`}
            type="submit"
            name="submit"
            value={intl.t("authenticate.create.submit", "Save and Continue")}
            title={intl.t("authenticate.create.submit", "Save and Continue")}
          />
        </fieldset>
      </Form>
    );
  }

  renderSuggestionMessage(suggestion) {
    return (
      <Text
        id={"authenticate.create.did-you-mean"}
        defaultMessage={"Did you mean"}
        values={{
          suggestion: (
            <SuggestionButton
              data-test-suggestion-button
              type={"button"}
              onClick={() => {
                this.setState({
                  email: suggestion,
                  emailSuggestion: undefined,
                });
              }}
            >
              {suggestion}
            </SuggestionButton>
          ),
        }}
      />
    );
  }

  async validateEmailServerSide(email) {
    const suggestion = await validateEmail(email);

    if (suggestion) {
      this.setState((state) => {
        if (state.email === email) {
          return {
            emailSuggestion: this.renderSuggestionMessage(suggestion),
          };
        }
      });
    } else {
      this.setState({
        emailSuggestion: null,
      });
    }
  }
}
