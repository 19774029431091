import fetch from "../utils/fetch";

export async function validateEmail(email) {
  const response = await fetch("/users/validate_email", {
    method: "POST",
    body: JSON.stringify({
      source: "home",
      email: email,
    }),
  }).catch(() => {
    return { suggestion: undefined };
  });

  return response.suggestion;
}
