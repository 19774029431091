import styled from "styled-components";
import classNames from "classnames/bind";

// Services
import intl from "../../services/intl";

// Utils
import { Color, rem, rgba } from "../../utils/style";
import { Icons } from "../../utils/react-svg";

const Container = styled.div`
  display: flex;
  align-items: center;
  margin: auto;
  margin-top: 20px;

  label {
    margin: 0 0 0 16px;
    font-weight: 400;
    font-size: ${rem(16)};
    line-height: ${rem(20)};
    cursor: pointer;
  }
`;

const CustomCheckbox = styled.span`
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  border-radius: 4px;
  border: 1px solid ${rgba(Color.ritualBlue, 0.24)};

  &.checked {
    background-color: ${Color.ritualBlue};
    border: 1px solid ${Color.ritualBlue};
  }

  display: flex;
  align-items: center;
  justify-content: center;

  pointer-events: none;

  svg {
    height: 8px;
    width: 12px;

    path {
      fill: ${Color.white};
    }
  }
`;

const BrowserCheckbox = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 2;
    background-color: ${Color.white};
    content: "";
  }
`;

const CheckboxContainer = styled.div`
  position: relative;
  height: 24px;
  width: 24px;
  flex: 1 0 auto;
`;

const NewsletterCheckbox = (props) => {
  const { value, onValueChange } = props;

  const handleChange = () => {
    onValueChange(!value);
  };

  return (
    <Container>
      <CheckboxContainer>
        <BrowserCheckbox
          type="checkbox"
          id="newsletter-checkbox"
          name={"newsletter-checkbox"}
          value={value}
          checked={value}
          onChange={handleChange}
        />
        <CustomCheckbox className={classNames({ checked: value })}>
          {value && <Icons.CheckRounded />}
        </CustomCheckbox>
      </CheckboxContainer>
      <label htmlFor="newsletter-checkbox">
        {intl.t(
          "authenticate.create.newsletter",
          "Yes, my inbox could use Ritual’s newsletter.",
        )}
      </label>
    </Container>
  );
};

export default NewsletterCheckbox;
