import { useState } from "react";
import styled from "styled-components";

import intl from "../../services/intl";

// Utils
import { Color, Font, Opacity, rem, responsive } from "../../utils/style";

// Config
import locales from "../../../config/locales";

// Components
import CreateForm from "./CreateForm";
import MagicLink from "../MagicLink";
import Text from "../Text";

import ThirdPartyAuth from "./ThirdPartyAuth";
import NewsletterCheckbox from "./NewsletterCheckbox";

// Styled Elements
const Wrapper = styled.div.attrs({
  className: "d-flex flex-column",
})``;

const CreateHeading = styled.div`
  margin-bottom: 32px;
  text-align: center;

  ${responsive.md`
    margin-bottom: 40px;
  `}
  h1 {
    margin: 0;
    font-weight: 500;
    letter-spacing: 0px;
    margin-bottom: 8px;
    text-align: center;

    font-size: ${rem(18)};
    line-height: ${rem(28)};

    ${responsive.md`
      font-size: ${rem(20)};
      line-height: ${rem(30)};
    `}
  }

  p {
    ${Font.dutch};
    font-size: ${rem(16)};
    letter-spacing: 0px;
    line-height: ${rem(26)};
    font-weight: normal;
    margin-bottom: 0;

    ${responsive.md`
    font-size: ${rem(18)};
    line-height: ${rem(28)};
  `}
  }
`;

const LoginText = styled.p`
  ${Font.dutch};
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  margin-top: 24px;
  margin-bottom: 0;

  width: 101%;

  ${responsive.sm`
    width: 107%;
  `}

  ${responsive.md`
    font-size: ${rem(18)};
    line-height: ${rem(28)};
    width: auto;
    margin-top: 40px;
  `}
`;

const LoginTextLink = styled(MagicLink)`
  padding-bottom: 2px;
  border-bottom: 1px solid ${Color.ritualBlue};
  transition: opacity 0.2s;

  &:hover {
    opacity: ${Opacity.light};
  }
`;

const TermsLinks = styled.span`
  margin: 20px auto 0;
  opacity: 0.56;
  font-weight: 400;
  font-size: ${rem(16)};
  line-height: ${rem(20)};

  a {
    border-bottom: 1px solid ${Color.ritualBlue};

    &:hover {
      opacity: 0.56;
    }
  }
`;

const CreateElements = (props) => {
  const { redirect } = props;

  const [showFormToggled, setShowFormToggled] = useState(false);

  const newsletterCheckboxEnabledDefault =
    locales[intl.locale]["newsletter-checkbox-enabled"];

  const [marketingPreference, setMarketingPreference] = useState(
    newsletterCheckboxEnabledDefault,
  );

  return (
    <Wrapper>
      <CreateHeading>
        <h1>
          <Text
            id="authenticate.create.sign-up-heading"
            defaultMessage="Sign Up"
          />
        </h1>
        <p>
          <Text
            id="authenticate.create.create-account-p"
            defaultMessage="New here? Don’t be a stranger."
          />
        </p>
      </CreateHeading>

      <ThirdPartyAuth
        onRitualFormToggleClicked={() => {
          setShowFormToggled(true);
        }}
        buttonType={"sign-up"}
        redirect={redirect}
        marketingPreference={marketingPreference}
      />
      {showFormToggled && (
        <CreateForm
          redirect={redirect}
          marketingPreference={marketingPreference}
        />
      )}
      <TermsLinks
        dangerouslySetInnerHTML={{
          __html: intl.t("authenticate.create.terms"),
        }}
      />
      <NewsletterCheckbox
        value={marketingPreference}
        onValueChange={setMarketingPreference}
        label={"marketing preference label"}
      />
      <></>
      <LoginText>
        <Text
          id="authenticate.create.already-have-account"
          defaultMessage="Already have an account?"
        />{" "}
        <LoginTextLink to="/login" state={{ redirect }}>
          <Text id="authenticate.create.sign-in" defaultMessage="Sign in" />
        </LoginTextLink>
        .
      </LoginText>
    </Wrapper>
  );
};

export default CreateElements;
