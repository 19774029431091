import { Component } from "react";
import { graphql } from "gatsby";

// Components
import LoadingWrapper from "../../components/global/LoadingWrapper";
import PageSEO from "../../components/seo/Page";
import PageHero from "../../components/PageHero";
import AuthenticateContainer from "../../components/authenticate/AuthenticateContainer";
import CreateElements from "../../components/authenticate/CreateElements";
import BarrierBusters from "../../components/authenticate/BarrierBusters";

// Redux
import { connect } from "react-redux";
import appSelectors from "../../store/app/selectors";
import userSelectors from "../../store/user/selectors";

const SignupPage = class SignupPageTemplate extends Component {
  componentDidMount() {
    this.props.updatePageData({
      label: "Signup",
    });
  }

  render() {
    const seo = {
      pagePath: "/signup",
      title: "Sign Up",
      description: "Sign Up",
    };

    const { heroImageMobile, heroImageDesktop } = this.props.data;
    const redirect = this.props.location?.state?.redirect;

    return (
      <>
        <PageSEO {...seo} noIndex={true} />

        <LoadingWrapper
          isLoading={!this.props.isUserLoaded}
          shouldRedirectOnLoad={this.props.isLoggedIn}
        >
          <PageHero
            imageMobile={heroImageMobile}
            imageDesktop={heroImageDesktop}
            imageObjectPosition="50% 100%"
            className="height-fit full-width-mobile"
          >
            <AuthenticateContainer>
              <CreateElements redirect={redirect || "/"} />
            </AuthenticateContainer>
          </PageHero>

          <BarrierBusters />
        </LoadingWrapper>
      </>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    isUserLoaded: appSelectors.isUserLoaded(state),
    isLoggedIn: userSelectors.isLoggedIn(state),
  };
};

export default connect(mapStateToProps, {})(SignupPage);

export const pageQuery = graphql`
  query SignUpQuery($locale: String!) {
    heroImageMobile: contentfulAsset(
      node_locale: { eq: $locale }
      contentful_id: { eq: "7Dathy6nXb81LmIIv8mXZA" }
    ) {
      description
      id
      gatsbyImageData(
        layout: CONSTRAINED
        placeholder: DOMINANT_COLOR
        width: 912
        quality: 80
      )
    }
    heroImageDesktop: contentfulAsset(
      node_locale: { eq: $locale }
      contentful_id: { eq: "4huChKZwlYOBIDx5pCVboj" }
    ) {
      description
      id
      gatsbyImageData(
        layout: CONSTRAINED
        placeholder: DOMINANT_COLOR
        width: 1920
        quality: 80
      )
    }
  }
`;
